const config = {
    // customisation
    env: 'domologique',
    color: '#76b82a',
    rgbPDF: {
        r: 0.463,
        g: 0.722,
        b: 0.165
    },
    header: {
        backgroundPosition: '50% 65%',
        backgroundSize: 'cover',
        logoContainerPadding: 15,
        logo: {
            height: '100',
            width: '100',
            backgroundColor: 'transparent',
            href: 'https://www.domologique.fr'
        },
        title: ''
    },
    name: 'Domologique',
    custom: {
        // ajouter une clé pour ajouter une ligne
        1: 'SARL DOMOLOGIQUE',
        2: '05 35 45 00 00'
    },
    mail: 'contact@domologique.fr',
    privacy: 'https://www.domologique.fr/politique-de-confidentialite/',
    legal: 'https://www.domologique.fr/mentions-legales/',
    modalType: '', // 'achille' or ''
    hotline: '', // '09 00 00 00 00' or ''
    hotjar: {
        hjid: 0,
        hjsv: 0
    },
    googleAnalyticId: '',

    apiGoogle: {
        key: 'AIzaSyCRMyPFYFvqYqWSGAcoWBDNSSzy7ZliNxU',
        libraries: ['geometry', 'places'],
    },
    // internal urls
        sendFormUrl: 'https://simulateur.domologique.fr/sendPDF',
        fetchBill: 'https://simulateur.domologique.fr/estimated-invoice',
        fetchEstimation: 'https://simulateur.domologique.fr/pre-calculate',

    // external urls
    achilleUrl: 'https://domologique.fhe-france.com',
};

export default config;

